import { ISeller } from "./common";
import { IArea } from "./location";

export interface IWarehouse {
  id: number;
  code: string;
  name: string;
  sellerId: ISeller["id"];
  siteId: number;
  siteName: string;
  siteCode: string;
  warehouseType: WarehouseTypes;
  isConsignment: boolean;
}

export interface IWarehouseQueryParam {
  siteId?: number;
  warehouseType?: WarehouseTypes;
  warehouseCodes?: Array<string>;
  page?: number;
  pageSize?: number;
  sellerId?: number;
}

export interface IWarehouseCreatePayload {
  code: string;
  name: string;
  siteId: number;
  sellerId: number;
  isConsignment: boolean;
  warehouseType: WarehouseTypes;
}

export enum WarehouseTypes {
  sale = 1,
  storeonly = 2,
  demo = 3,
  refurbished = 4,
  backorder = 5,
  incoming = 6,
  notforsale = 7,
}

export interface ISite {
  id: number;
  code: string;
  name: string;
  address?: string;
  city?: string;
  fulfillmentChannelId?: number;
  isStore?: boolean;
}

export interface ISiteQueryParam {
  cityId?: number;
  fulfillmentChannelId?: number;
  siteCodes?: Array<string>;
  page?: number;
  pageSize?: number;
  sellerId?: number;
}

export interface ISiteCreatePayload {
  site: ISiteDetailData["site"];
  locationNear: Array<string>;
  locationBulky: Array<string>;
}

export interface ISiteUpdatePayload {
  site: ISiteDetailData["site"];
  locationNear: Array<string>;
  isUpdateLocationNear: boolean;
  locationBulky: Array<string>;
  isUpdateLocationBulky: boolean;
}

export interface ISiteDetailData {
  site: {
    code: string;
    name: string;
    isStore: boolean;
    address: string;
    city: string;
    locationId: string;
    priority: number;
    fulfillmentChannel: number;
    accountingId: number;
    id?: number;
    phoneNumber?: string;
  };
  locationNear: Array<IArea>;
  locationBulky: Array<IArea>;
}

export interface ISiteV2 {
  id: number;
  name: string;
  city: string;
  isStore: boolean;
  address: string;
  locationId: string;
  isPickupPlace: boolean;
  fulfillmentProviderId: number;
  code?: string;
  terminalId?: number;
  phoneNumber?: string;
  fulfillmentProviderName?: string;
  configSiteShippingTypeLocations?: IConfigSiteShippingTypeLocation[];
  isActive?: boolean;
  isOneBin?: boolean;
}

export interface ISiteV2GetListPayload {
  provinceId?: string;
  siteId?: number;
  siteName?: string;
  includeConfigShippingType?: boolean;
  isActive?: boolean;
}

export interface ISiteV2GetListFilter extends Omit<ISiteV2GetListPayload, "isActive"> {
  isActive?: ActiveStatus;
}

export interface ISiteDetailDataV2 extends Omit<ISiteV2, "fulfillmentProviderName" | "terminalId"> {
  accountingId?: number;
}

export interface ISiteV2CreatePayload {
  site: ISiteCreateDetailV2;
}

export interface ISiteCreateDetailV2
  extends Omit<ISiteV2, "id" | "fulfillmentProviderName" | "terminalId" | "configSiteShippingTypeLocations"> {
  configSiteShippingTypeLocations: IConfigSiteShippingTypeLocationsCreate[];
  accountingId: number;
}

export interface IConfigSiteShippingTypeLocationsCreate {
  shippingType: string;
  locationIds: string[];
}

export interface ISiteV2UpdatePayload {
  site: ISiteDetailDataV2;
}

export interface ISiteV2CreateResponse {
  id: ISiteV2["id"];
  message: string;
}

export interface ISiteShippingTypeLocationPayload {
  data: ISiteShippingTypeLocation[];
}

export interface ISiteShippingTypeLocation {
  siteId: string;
  shippingType: string;
  locationIds: string[];
}

export interface IFulfillmentProvider {
  id: string;
  name: string;
}

export interface ProvinceMap {
  [key: string]: string;
}

export interface FulfillmentProviderMap {
  [key: string]: string;
}

export interface IConfigSiteShippingTypeLocation {
  locations: IShippingTypeLocation[];
  shippingTypeCode: string;
  shippingTypeName?: string;
}

export interface IShippingTypeLocation {
  locationId: string;
  locationName?: string;
}

export interface ISellerSite {
  id: number;
  name: string;
  code: string;
}

export interface ISitesBySeller {
  [sellerId: string]: ISellerSite[];
}

export interface IGetSellerSitesParams {
  isActive?: boolean;
}

export enum ActiveStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}
