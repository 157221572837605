import { notification } from "antd";
import { userApis } from "apis";
import { AxiosError } from "axios";
import { notifyErrorInfo } from "components/ErrorInfoMessage";
import { getErrorWithSugaryWrapper } from "components/RequestInterceptorUtils";
import { commonConstants } from "constants/index";
import { get } from "lodash";
import { ErrorDetails, ErrorInfo, ErrorInfoDomain } from "models";
import { isTranslationExist, t } from "utils/i18n";

const { BYPASSED_NOT_FOUND_ERROR_URLS, URL_SEARCH_PARAMS_REGEX } = commonConstants;

const bypass403ErrorMap: { [domain: string]: { [reason: string]: boolean } } = {
  [ErrorInfoDomain.StaffOMAdminBFF]: {
    DISALLOWED_PLATFORM_ID: true,
  },
};

const handleResponseError = (error: AxiosError) => {
  const status = error?.response?.status;

  let errorInfo: ErrorInfo | undefined;
  if (Array.isArray(error?.response?.data?.details)) {
    const errorDetailsArr = error?.response?.data?.details as ErrorDetails[];
    errorInfo = errorDetailsArr.find((errDetails) =>
      isTranslationExist(`ApiErrorMessages.${errDetails?.value?.domain}.${errDetails?.value?.reason}`)
    )?.value;
  }

  /* if error details is not array, or not in ErrorInfo format
  * ErrorInfo format:
    {
      "reason": "INSUFFICIENT_STOCK",
      "domain": "warehouse3-inventory-service@warehouse-management",
      "metadata": {
        "skus": "1800809"
      }
    }
  * then fallback to the old flow
  */
  if (!errorInfo) {
    return handleResponseErrorOldFlow(error);
  }

  // if error info is in correct format, then continue with the current flow
  switch (status) {
    case 401:
      trackError(error);
      return userApis.logout();
    case 403:
      trackError(error, getMessageFromErrorInfo(errorInfo));
      if (!bypass403ErrorMap[errorInfo.domain]?.[errorInfo.reason]) {
        return userApis.denyAccess();
      }
      return notifyErrorInfo(errorInfo);
    case 404:
      return handle404Error(error, errorInfo, { url: error.config?.url || "" });
    default:
      trackError(error, getMessageFromErrorInfo(errorInfo));
      return notifyErrorInfo(errorInfo);
  }
};

const handle404Error = (error: AxiosError, errorInfo: ErrorInfo, errorConfigs: { url: string }) => {
  const canByPassed = BYPASSED_NOT_FOUND_ERROR_URLS.some((regex) => regex.test(errorConfigs.url));
  if (canByPassed) {
    return;
  }
  trackError(error, getMessageFromErrorInfo(errorInfo));
  return notifyErrorInfo(errorInfo);
};

const handleResponseErrorOldFlow = (error: AxiosError) => {
  let errorMessage = null;
  const status = error && error.response && error.response.status;

  switch (status) {
    case 401:
      trackError(error);
      userApis.logout();
      break;
    case 403:
      trackError(error);
      userApis.denyAccess();
      break;
    case 404:
      const url = error.response?.config?.url || "";
      const canByPassed = BYPASSED_NOT_FOUND_ERROR_URLS.some((regex) => regex.test(url));
      if (canByPassed) {
        break;
      }
      errorMessage = t("ApiMessages.Common.NotFound");
      if (error.response && error.response.data) {
        const { data } = error.response;
        errorMessage = data.details?.errorCode ? t(`ApiMessages.Common.${data.details.errorCode}`) : errorMessage;
      }
      notification.error({
        message: t("Error"),
        description: errorMessage,
      });
      trackError(error, errorMessage);
      break;
    case 409:
      errorMessage = t("ApiMessages.Common.AlreadyExisted");
      if (error.response && error.response.data) {
        const { data } = error.response;
        errorMessage = data.details?.errorCode ? t(`ApiMessages.Common.${data.details.errorCode}`) : errorMessage;
      }
      notification.error({
        message: t("Error"),
        description: errorMessage,
      });
      trackError(error, errorMessage);
      break;
    default:
      errorMessage = t("ApiMessages.Common.UnknownError");
      if (error.response && error.response.data) {
        const { data } = error.response;

        if (data?.code && isTranslationExist(`ApiMessages.ErrorCode.${data?.code}`)) {
          errorMessage = t(`ApiMessages.ErrorCode.${data?.code}`);
          notification.error({
            message: t("Error"),
            description: errorMessage,
          });
        } else {
          errorMessage = data.details?.errorCode
            ? t(`ApiMessages.Common.${data.details.errorCode}`)
            : data.message
            ? data.message
            : errorMessage;

          notification.error({
            message: t("Error"),
            description: getErrorWithSugaryWrapper(errorMessage),
          });
        }
        trackError(error, errorMessage);
      } else {
        trackError(error);
      }
      break;
  }
};

const trackError = (error: AxiosError, errMessage?: string) => {
  const status = get(error, ["response", "status"], ".");
  // Track error
  const errResponse = get(error, ["response"]);
  if (errResponse) {
    // Remove search params from apiCall
    const apiCall = get(errResponse, ["request", "responseURL"], ".").replace(URL_SEARCH_PARAMS_REGEX, "");

    const getErrCodeAndMessage = () => {
      const isBFFErr = !!get(errResponse, ["data", "code"]);
      if (isBFFErr)
        return {
          errorCode: get(errResponse, ["data", "code"], ".").toString(),
          errorMessage: errMessage || get(errResponse, ["data", "message"], "."),
        };
      else {
        return {
          errorCode: get(errResponse, ["data", "error", "code"], ".").toString(),
          errorMessage: errMessage || get(errResponse, ["data", "error", "message"], "."),
        };
      }
    };

    setTimeout(() => {
      track("error", {
        errorSource: "erp_staff_desktop",
        apiCall,
        apiPayload: get(errResponse, ["config", "data"], JSON.stringify(get(errResponse, ["config", "params"], {}))),
        httpResponseCode: status?.toString(),
        responseJson: JSON.stringify(get(errResponse, "data", {})),
        ...getErrCodeAndMessage(),
      });
    });
  }
};

const getMessageFromErrorInfo = (errorInfo: ErrorInfo) => {
  return t(`ApiErrorMessages.${errorInfo?.domain}.${errorInfo?.reason}`, errorInfo?.metadata);
};

export default {
  handleResponseError,
};
